export const PointerUpIcon = ({ ariaHidden = true }) => (
  <svg
    aria-hidden={ariaHidden}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 12.1245L5.41 13.5345L11 7.95451V20.1245H13V7.95451L18.58 13.5445L20 12.1245L12 4.12451L4 12.1245Z"
      fill="white"
    />
  </svg>
);
