import React from 'react';

import cn from 'classnames';

import styles from './StoryblokButtonWrapper.module.scss';

export const StoryblokButtonWrapper = ({ children, align = 'left' }) => {
  const alignButton = {
    center: styles.alignCenter,
    left: styles.alignLeft,
    right: styles.alignRight,
  };

  return <div className={cn(styles.buttonWrapper, alignButton[align.toLowerCase()])}>{children}</div>;
};
