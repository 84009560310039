export const WasherDryerIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.375 13.625H9C8.65482 13.625 8.375 13.3452 8.375 13C8.375 12.6548 8.65482 12.375 9 12.375H11.375V10C11.375 9.65482 11.6548 9.375 12 9.375C12.3452 9.375 12.625 9.65482 12.625 10V12.375H15C15.3452 12.375 15.625 12.6548 15.625 13C15.625 13.3452 15.3452 13.625 15 13.625H12.625V16C12.625 16.3452 12.3452 16.625 12 16.625C11.6548 16.625 11.375 16.3452 11.375 16V13.625Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.375 13C5.375 9.34111 8.34111 6.375 12 6.375C15.6589 6.375 18.625 9.34111 18.625 13C18.625 16.6589 15.6589 19.625 12 19.625C8.34111 19.625 5.375 16.6589 5.375 13ZM12 7.625C9.03147 7.625 6.625 10.0315 6.625 13C6.625 15.9685 9.03147 18.375 12 18.375C14.9685 18.375 17.375 15.9685 17.375 13C17.375 10.0315 14.9685 7.625 12 7.625Z"
      fill="black"
    />
    <path
      d="M5.875 4.5C5.875 4.15482 6.15482 3.875 6.5 3.875H17.5C17.8452 3.875 18.125 4.15482 18.125 4.5C18.125 4.84518 17.8452 5.125 17.5 5.125H6.5C6.15482 5.125 5.875 4.84518 5.875 4.5Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 1.25C3.58579 1.25 3.25 1.58579 3.25 2V22C3.25 22.4142 3.58579 22.75 4 22.75H20C20.4142 22.75 20.75 22.4142 20.75 22V2C20.75 1.58579 20.4142 1.25 20 1.25H4ZM4.75 21.25V2.75H19.25V21.25H4.75Z"
      fill="black"
    />
  </svg>
);
