import React from 'react';

import cn from 'classnames';

import styles from './BlueButton.module.scss';

export const BlueButton = ({
  text,
  onClick,
  isSubmit,
  mobileFullWidth,
  disabled,
  children,
  background = 'blueButton',
  dataAttribute,
  className,
}) => {
  return (
    <div className={cn(styles.container, className)}>
      <button
        type={isSubmit ? 'submit' : 'button'}
        className={cn(styles.blueButton, styles[background], { [styles.fullWidth]: mobileFullWidth })}
        onClick={onClick}
        disabled={disabled}
        {...dataAttribute}
      >
        {children || <span>{text}</span>}
      </button>
    </div>
  );
};
