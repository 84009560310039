export const PlantIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.65257 7.5564C6.65257 7.5564 8.48001 9.36021 8.75023 9.63941C12.1282 13.1296 12.1282 17.6757 12.1282 20.3958M16.9899 8.05698C16.9899 8.05698 15.2182 9.87596 15.0286 10.1151C13.4589 12.0956 12.1282 14.6376 12.1282 19.1375M2.12815 3.12965C1.39946 9.33879 3.91902 11.1296 6.12815 11.1296C8.33729 11.1296 10.1282 9.33879 10.1282 7.12965C10.1282 4.92051 8.21815 2.39561 2.12815 3.12965ZM21.0178 4.24128C21.6453 9.58763 19.4755 11.1296 17.573 11.1296C15.6705 11.1296 14.1282 9.58763 14.1282 7.68546C14.1282 5.78329 15.7731 3.60924 21.0178 4.24128Z"
      stroke="black"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
