/* eslint-disable import/no-cycle */
import React from 'react';

import cn from 'classnames';
import { decodeHTML } from 'entities/lib/decode';
import Link from 'next/link';
import {
  StoryblokRichtext,
  render,
  MARK_LINK,
  MARK_BOLD,
  MARK_TEXT_STYLE,
  NODE_HEADING,
  NODE_LI,
  NODE_UL,
  NODE_OL,
  NODE_PARAGRAPH,
} from 'storyblok-rich-text-react-renderer';

import { DeprecatedIconButton } from '@components/Button/IconButton/IconButton';
import { FaqGroup } from '@components/FaqGroup/FaqGroup';
import { ExternalLink } from '@components/Link/ExternalLink';
import { InternalLink } from '@components/Link/InternalLink';
import { TextWithLink } from '@components/Link/TextWithLink';
import { Markdown } from '@components/Markdown/Markdown';
import { StoryblokButtonWrapper } from '@components/StoryblokButtonWrapper/StoryblokButtonWrapper';
import { TitleBlock } from '@components/TitleBlock/TitleBlock';
import { useLocale } from '@hooks/useLocale';
import { CancellationSetReminderButton } from '@modules/UserPanel/Cancellation/components/CancellationSetReminderButton/CancellationSetReminderButton';
import { interpolateContent } from '@utils/interpolate-content';
import { buildPathForLocale } from '@utils/paths/build-paths';
import { PathsConfig } from '@utils/paths/paths-config';

import styles from './RichText.module.scss';
import BlueCheckmarkIcon from '../../assets/icons/new/blueCheckmark.svg';
import { NotificationBar } from '../NotificationBar/NotificationBar';
import { StoryblokButton } from '../StoryblokButton/StoryblokButton';

import { removeLeadingSlash } from '@helpers/remove-leading-slash';
import { mapStoryblokSlugToPageId } from '@helpers/storyblok-slug-to-page-id';

// https://github.com/claus/storyblok-rich-text-react-renderer

const markResolvers = ({ contentVariables, locale }) => {
  return {
    [MARK_LINK]: (children, props) => {
      const { href, target, linktype, anchor } = props;

      if (linktype === 'email') {
        return <a href={`mailto:${href}`}>{children}</a>;
      }

      if (href.match(/^(https?:)?\/\//) || href.match(/^tel:.*$/)) {
        return (
          <a href={href} target={target} className={styles.link}>
            {children}
          </a>
        );
      }

      if (anchor) {
        return (
          <span
            onClick={() => {
              const element = document?.getElementById(anchor);
              element.scrollIntoView({ behavior: 'smooth' });
            }}
            className={styles.link}
          >
            {children}
          </span>
        );
      }

      const storyblokSlugPaths = mapStoryblokSlugToPageId(PathsConfig);
      const hrefWithoutLeadingSlash = removeLeadingSlash(href);

      if (storyblokSlugPaths?.[hrefWithoutLeadingSlash]) {
        return (
          <Link
            target={target || '_self'}
            href={buildPathForLocale({ pageId: storyblokSlugPaths?.[hrefWithoutLeadingSlash], locale })}
          >
            <span className={styles.link}>{children}</span>
          </Link>
        );
      }

      return (
        <Link href={interpolateContent(href, contentVariables) || href}>
          <span className={styles.link}>{children}</span>
        </Link>
      );
    },
    [MARK_BOLD]: children => <b className={styles.textBold}>{children}</b>,
    [MARK_TEXT_STYLE]: (children, props) => <span style={{ ...props }}>{children}</span>,
  };
};

const mappedClassNameToTypographyLevel = {
  1: 'hero',
  2: 'headline',
  3: 'title',
  4: 'subtitle',
  5: 'lead',
  6: 'text',
};

const nodeResolvers = {
  [NODE_HEADING]: (children, { level }) => {
    const HTag = `h${level}` as any;

    return <HTag className={styles[mappedClassNameToTypographyLevel[level]]}>{children}</HTag>;
  },
  [NODE_PARAGRAPH]: children => <p className={styles.p}>{children}</p>,
  [NODE_UL]: children => <ul className={styles.ul}>{children}</ul>,
  [NODE_OL]: children => <ol className={styles.ol}>{children}</ol>,
  [NODE_LI]: children => (
    <li className={styles.li}>
      <>
        <BlueCheckmarkIcon />
        {children}
      </>
    </li>
  ),
};

// maybe we should have separate list of supported components in rich text and group them appropriately in storybook/storyblok
const blokResolvers = {
  titleBlock: props => <TitleBlock {...props} />,
  faqGroup: props => <FaqGroup {...props} />,
  iconButton: props => (
    // TODO BUTTON REMOVE IF NOT USED IN STORYBLOK
    <StoryblokButtonWrapper>
      <DeprecatedIconButton {...props} />
    </StoryblokButtonWrapper>
  ),
  button: props => <StoryblokButton {...props} />,
  internalLink: props => <InternalLink className={styles.link} {...props} />,
  externalLink: props => <ExternalLink className={styles.link} {...props} />,
  cancellationSetReminderButton: props => <CancellationSetReminderButton {...props} />,
  notificationBar: props => <NotificationBar {...props} />,
  textWithLink: props => <TextWithLink {...props} />,
};

export const RichText = ({
  document,
  className,
  contentVariables,
}: {
  document: StoryblokRichtext;
  className?: string;
  contentVariables?: Record<string, string>;
}) => {
  // document is the rich text object you receive from Storyblok,
  // in the form { type: "doc", content: [ ... ] }
  const { locale } = useLocale();

  return (
    // eslint-disable-next-line react/no-unknown-property
    /* @ts-ignore */
    <div className={cn(styles.container, className)} outsideclickmarker="sheetLink">
      {render(document, {
        markResolvers: markResolvers({ contentVariables, locale }),
        blokResolvers,
        nodeResolvers,
        defaultBlokResolver: (name, props) => (
          <div>
            <code>Missing blok resolver for blok type &quot;{name}&quot;.</code>
            <pre>
              <code>{JSON.stringify(props, undefined, 2)}</code>
            </pre>
          </div>
        ),
        defaultStringResolver: str => {
          if (Array.isArray(str)) {
            return str as any;
          }

          return <Markdown>{str}</Markdown>;
        },
        textResolver: text =>
          (contentVariables && interpolateContent(decodeHTML(text), contentVariables)) || (decodeHTML(text) as any),
      })}
    </div>
  );
};
