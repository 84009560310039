export const StoveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 18.5V3H20V18.5H4ZM4 20V21H20V20H4ZM2.5 2.5C2.5 1.94772 2.94772 1.5 3.5 1.5H20.5C21.0523 1.5 21.5 1.94772 21.5 2.5V21.5C21.5 22.0523 21.0523 22.5 20.5 22.5H3.5C2.94771 22.5 2.5 22.0523 2.5 21.5V2.5ZM8.24997 5.50553C9.21183 5.50487 10.0036 6.29096 10.0055 7.2485C10.0074 8.20856 9.21341 9.00825 8.261 9.00547C7.29529 9.00271 6.5087 8.22068 6.50551 7.26029C6.50231 6.2991 7.29057 5.50618 8.24997 5.50553ZM5.00551 7.26528C5.01148 9.05805 6.47378 10.5003 8.25662 10.5055L8.25671 10.5055C10.0518 10.5107 11.509 9.02538 11.5055 7.24553V7.24549C11.5019 5.45399 10.0321 4.00431 8.24895 4.00553C6.45691 4.00674 4.99956 5.47678 5.00551 7.26528ZM17.5055 7.2485C17.5036 6.29096 16.7118 5.50487 15.75 5.50553C14.7906 5.50618 14.0023 6.2991 14.0055 7.26029C14.0087 8.22068 14.7953 9.00271 15.761 9.00547C16.7134 9.00825 17.5074 8.20856 17.5055 7.2485ZM15.7566 10.5055C13.9738 10.5003 12.5115 9.05805 12.5055 7.26528C12.4996 5.47678 13.9569 4.00674 15.749 4.00553C17.5321 4.00431 19.0019 5.45399 19.0055 7.24549L19.0055 7.24553C19.009 9.02538 17.5518 10.5107 15.7567 10.5055L15.7566 10.5055ZM8.24997 12.5055C9.21183 12.5049 10.0036 13.291 10.0055 14.2485C10.0074 15.2086 9.21341 16.0083 8.261 16.0055C7.29529 16.0027 6.5087 15.2207 6.50551 14.2603C6.50231 13.2991 7.29057 12.5062 8.24997 12.5055ZM5.00551 14.2653C5.01148 16.058 6.47378 17.5003 8.25662 17.5055H8.25671C10.0518 17.5107 11.509 16.0254 11.5055 14.2455V14.2455C11.5019 12.454 10.0321 11.0043 8.24895 11.0055C6.45691 11.0067 4.99956 12.4768 5.00551 14.2653ZM17.5055 14.2485C17.5036 13.291 16.7118 12.5049 15.75 12.5055C14.7906 12.5062 14.0023 13.2991 14.0055 14.2603C14.0087 15.2207 14.7953 16.0027 15.761 16.0055C16.7134 16.0083 17.5074 15.2086 17.5055 14.2485ZM15.7566 17.5055C13.9738 17.5003 12.5115 16.058 12.5055 14.2653C12.4996 12.4768 13.9569 11.0067 15.749 11.0055C17.5321 11.0043 19.0019 12.454 19.0055 14.2455L19.0055 14.2455C19.009 16.0254 17.5518 17.5107 15.7567 17.5055H15.7566Z"
      fill="black"
    />
  </svg>
);
