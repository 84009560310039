export const TrashIcon = ({ ariaHidden = true }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 6V19H8V6H16ZM14 3H10L9 4H5V6H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V6H19V4H15L14 3ZM10 16L10 9H11L11 16H10ZM13 9L13 16H14V9H13Z"
      fill="#141B4A"
    />
  </svg>
);
