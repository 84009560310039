export const DryerIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 12C16 11.7239 15.7761 11.5 15.5 11.5C15.2239 11.5 15 11.7239 15 12V14C15 14.2761 15.2239 14.5 15.5 14.5C15.7761 14.5 16 14.2761 16 14V12Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.375 13C5.375 9.34111 8.34111 6.375 12 6.375C15.6589 6.375 18.625 9.34111 18.625 13C18.625 16.6589 15.6589 19.625 12 19.625C8.34111 19.625 5.375 16.6589 5.375 13ZM12 7.625C9.03147 7.625 6.625 10.0315 6.625 13C6.625 15.9685 9.03147 18.375 12 18.375C14.9685 18.375 17.375 15.9685 17.375 13C17.375 10.0315 14.9685 7.625 12 7.625Z"
      fill="black"
    />
    <path
      d="M5.5 4.5C5.5 4.15482 5.77982 3.875 6.125 3.875H8.875C9.22018 3.875 9.5 4.15482 9.5 4.5C9.5 4.84518 9.22018 5.125 8.875 5.125H6.125C5.77982 5.125 5.5 4.84518 5.5 4.5Z"
      fill="black"
    />
    <path
      d="M15.5128 3.75641C15.789 3.75641 16.0128 3.98027 16.0128 4.25641V4.76923C16.0128 5.04537 15.789 5.26923 15.5128 5.26923C15.2367 5.26923 15.0128 5.04537 15.0128 4.76923V4.25641C15.0128 3.98027 15.2367 3.75641 15.5128 3.75641Z"
      fill="black"
    />
    <path
      d="M14.0128 4.25641C14.0128 3.98027 13.789 3.75641 13.5128 3.75641C13.2367 3.75641 13.0128 3.98027 13.0128 4.25641V4.76923C13.0128 5.04537 13.2367 5.26923 13.5128 5.26923C13.789 5.26923 14.0128 5.04537 14.0128 4.76923V4.25641Z"
      fill="black"
    />
    <path
      d="M17.5641 3.75641C17.8402 3.75641 18.0641 3.98027 18.0641 4.25641V4.76923C18.0641 5.04537 17.8402 5.26923 17.5641 5.26923C17.2879 5.26923 17.0641 5.04537 17.0641 4.76923V4.25641C17.0641 3.98027 17.2879 3.75641 17.5641 3.75641Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 1.25C3.58579 1.25 3.25 1.58579 3.25 2V22C3.25 22.4142 3.58579 22.75 4 22.75H20C20.4142 22.75 20.75 22.4142 20.75 22V2C20.75 1.58579 20.4142 1.25 20 1.25H4ZM4.75 21.25V2.75H19.25V21.25H4.75Z"
      fill="black"
    />
  </svg>
);
