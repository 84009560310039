export const FridgeIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.2087 8.83508C18.2099 8.84738 18.2107 8.85975 18.2113 8.8722C18.2125 8.89972 18.2116 8.92692 18.2087 8.95369V21.0069C18.2022 21.5594 17.7884 21.9755 17.2401 21.9993C17.2127 22.0006 17.1859 22 17.1596 21.9975H6.84741C6.55704 21.9975 6.271 21.9152 6.06514 21.6942C5.88311 21.4948 5.80077 21.2456 5.80077 20.9791V8.86443C5.80046 8.85378 5.80046 8.84318 5.80077 8.83264V3.48069C5.80077 3.42651 5.80053 3.37233 5.80029 3.31815C5.7998 3.2098 5.79932 3.10145 5.80077 2.99311C5.80509 2.54238 6.10631 2.10466 6.56786 2.02448C6.68704 2.00498 6.78022 2.00065 6.89507 2.00065H16.8414C16.8709 2.00065 16.9005 2.00048 16.9301 2.00032C17.3066 1.99827 17.6843 1.99621 17.9595 2.32569C18.1286 2.52722 18.2087 2.76126 18.2087 3.0213V8.83508ZM16.9095 20.6992L16.909 20.5343C16.9088 20.4571 16.9086 20.3798 16.9086 20.3026V9.52571C16.8201 9.52534 16.7316 9.52477 16.6431 9.5242C16.4933 9.52324 16.3433 9.52228 16.1938 9.52228H9.42626V12.5645C9.42626 12.9285 9.1294 13.1994 8.77618 13.2145C8.42514 13.2297 8.1261 12.9047 8.1261 12.5645V9.52228H7.10087V20.5518C7.10087 20.6001 7.10052 20.6484 7.10017 20.6967L7.10013 20.7019C7.27529 20.7024 7.45026 20.7015 7.62536 20.7006C7.76524 20.6999 7.90545 20.6992 8.04565 20.6992H16.9095ZM8.1261 8.22211H7.8185C7.66908 8.22211 7.51941 8.22115 7.36966 8.22019C7.28009 8.21961 7.19047 8.21904 7.10087 8.21867V3.69714C7.10087 3.61993 7.10063 3.54272 7.10039 3.46544L7.09996 3.29545C7.22324 3.29516 7.3467 3.29658 7.46996 3.29801C7.58193 3.2993 7.69378 3.3006 7.80516 3.3006H15.9572C16.0982 3.3006 16.2391 3.29987 16.38 3.29915C16.5566 3.29824 16.7331 3.29734 16.9093 3.29786L16.9093 3.30307C16.9089 3.35136 16.9086 3.39966 16.9086 3.44795V8.22211H9.42626V5.17733C9.42626 4.83711 9.12722 4.51207 8.77618 4.52724C8.42513 4.54241 8.1261 4.81112 8.1261 5.17733V8.22211ZM17.2683 20.697C17.2645 20.698 17.2604 20.6985 17.2561 20.6988L17.2683 20.697Z"
      fill="black"
      stroke="black"
      stroke-width="0.25"
    />
  </svg>
);
