import React from 'react';

import { getLinkTarget } from '@helpers/get-link-target';

export const DeprecatedLink = ({ children, path, label, dataAttribute = {} }) => {
  return (
    <a href={path} title={label} target={getLinkTarget(path)} {...dataAttribute}>
      {children}
    </a>
  );
};
