import React from 'react';

import cn from 'classnames';

import styles from './RawBackButton.module.scss';

export const RawBackButton = ({ text, onClick, disabled, className }) => {
  return (
    <div className={styles.container}>
      <button type="button" className={cn(styles.button, className)} onClick={onClick} disabled={disabled}>
        <span className={styles.backArrowText}>{'< '}</span>
        {text}
      </button>
    </div>
  );
};
