export const HamburgerIcon = ({ ariaHidden = true }) => (
    <svg
        aria-hidden={ariaHidden}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.7085 6.75C3.7085 6.19772 4.15621 5.75 4.7085 5.75H19.2916C19.8438 5.75 20.2915 6.19772 20.2915 6.75C20.2915 7.30228 19.8438 7.75 19.2916 7.75H4.7085C4.15621 7.75 3.7085 7.30228 3.7085 6.75ZM3.7085 11.75C3.7085 11.1977 4.15621 10.75 4.7085 10.75H19.2916C19.8438 10.75 20.2915 11.1977 20.2915 11.75C20.2915 12.3023 19.8438 12.75 19.2916 12.75H4.7085C4.15621 12.75 3.7085 12.3023 3.7085 11.75ZM4.7085 15.75C4.15621 15.75 3.7085 16.1977 3.7085 16.75C3.7085 17.3023 4.15621 17.75 4.7085 17.75H19.2916C19.8438 17.75 20.2915 17.3023 20.2915 16.75C20.2915 16.1977 19.8438 15.75 19.2916 15.75H4.7085Z"
            fill="#141B4A"
        />
    </svg>
);
