export const UserIcon = ({ ariaHidden = true }) => (
  <svg
    aria-hidden={ariaHidden}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 5.25C13.2375 5.25 14.25 6.2625 14.25 7.5C14.25 8.7375 13.2375 9.75 12 9.75C10.7625 9.75 9.75 8.7375 9.75 7.5C9.75 6.2625 10.7625 5.25 12 5.25ZM12 15.375C15.0375 15.375 18.525 16.8263 18.75 17.625V18.75H5.25V17.6363C5.475 16.8263 8.9625 15.375 12 15.375ZM12 3C9.51375 3 7.5 5.01375 7.5 7.5C7.5 9.98625 9.51375 12 12 12C14.4862 12 16.5 9.98625 16.5 7.5C16.5 5.01375 14.4862 3 12 3ZM12 13.125C8.99625 13.125 3 14.6325 3 17.625V21H21V17.625C21 14.6325 15.0037 13.125 12 13.125Z"
      fill="black"
    />
  </svg>
);
