/* eslint-disable import/no-cycle */
import React, { useState } from 'react';

import cn from 'classnames';

import { Item } from './components/Item/Item';
import styles from './FaqGroup.module.scss';

export const FaqGroup = ({ items, notElevated, allOpened = false }) => {
  const defaultItemVisibility = items?.findIndex(i => i.opened);
  const [itemVisibilityIndex, setItemVisibilityIndex] = useState(defaultItemVisibility);
  const elevatedStyle = notElevated ? styles.notElevated : styles.elevated;
  const toggleItem = index => () => {
    if (allOpened) {
      return;
    }
    if (index === itemVisibilityIndex) {
      setItemVisibilityIndex(null);

      return;
    }
    setItemVisibilityIndex(index);
  };

  return (
    <div className={cn(styles.container, elevatedStyle)}>
      {items?.map((item, index) => (
        <Item
          key={`${item.question}-${index}`}
          item={item}
          isOpened={allOpened || itemVisibilityIndex === index}
          onClick={toggleItem(index)}
          allOpened={allOpened}
        />
      ))}
    </div>
  );
};
