export const PowerToolsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.92071 5C9.33254 3.83481 10.4438 3 11.75 3H18.25C20.7353 3 22.75 5.01472 22.75 7.5C22.75 9.63718 21.2601 11.4264 19.2624 11.8857L20.8206 19.4469C20.9321 19.9879 20.5839 20.5167 20.043 20.6282L18.0842 21.0319C17.5433 21.1433 17.0144 20.7952 16.9029 20.2543L15.6449 14.15H14.1904C13.1608 14.15 12.2244 13.5536 11.789 12.6206L11.4944 11.9893C10.7091 11.923 10.01 11.5543 9.51389 11H8.41667C7.08565 11 5.9824 10.0248 5.78236 8.75H2.75C2.33579 8.75 2 8.41421 2 8C2 7.58579 2.33579 7.25 2.75 7.25H5.78236C5.9824 5.97515 7.08565 5 8.41667 5H8.92071ZM15.377 12.85L15.2019 12H12.934L12.9671 12.0709C13.1889 12.5462 13.6659 12.85 14.1904 12.85H15.377ZM16.7334 12L18.2711 19.4618L19.2505 19.26L17.7544 12H16.7334ZM8.75 6.5H8.41667C7.77233 6.5 7.25 7.02233 7.25 7.66667V8.33333C7.25 8.97766 7.77233 9.5 8.41667 9.5H8.79148C8.7642 9.3374 8.75 9.17036 8.75 9V6.5ZM15.25 4.5H18.25C19.9069 4.5 21.25 5.84315 21.25 7.5C21.25 9.15685 19.9069 10.5 18.25 10.5H15.25V4.5ZM13.75 4.5H11.75C10.9216 4.5 10.25 5.17157 10.25 6V9C10.25 9.82843 10.9216 10.5 11.75 10.5H13.75V4.5Z"
      fill="black"
    />
  </svg>
);
