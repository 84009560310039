export const CheckmarkIcon = ({ ariaHidden = true }) => (
  <svg
    aria-hidden={ariaHidden}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M8.80911 15.8836L4.7523 11.8089L3.40002 13.1671L8.80911 18.6L20.4 6.95818L19.0478 5.59998L8.80911 15.8836Z"
      fill="black"
    />
  </svg>
);
