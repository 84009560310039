export const PDFIcon = ({ ariaHidden = true }) => (
  <svg
    aria-hidden={ariaHidden}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    style={{ fill: 'none' }}
  >
    <g clip-path="url(#clip0_6167_12040)">
      <path
        d="M5.19592 21.5431V2.46094H14.206L18.8169 6.9398V21.5431H5.19592Z"
        fill="white"
        style={{ fill: 'white' }}
      />
      <path
        d="M14.0106 2.9182L18.3449 7.12821V21.0822H5.66784V2.9178H14.0106M14.4014 2H4.724V22H19.2885V6.74732L14.4014 2Z"
        fill="black"
        style={{ fill: 'black' }}
      />
      <path
        d="M18.8169 21.5431H5.19586V2.46094H14.2059L14.3127 2.56476V6.83999H18.7136L18.8169 6.9398V21.5431Z"
        fill="white"
        style={{ fill: 'white' }}
      />
      <path
        d="M13.8407 2.91797V7.29685H18.3447V21.0822H5.66766V2.9178H13.8405M14.401 2H4.72363V22H19.2881V6.74732L18.9088 6.37882H14.7841V2.37251L14.401 2Z"
        fill="black"
        style={{ fill: 'black' }}
      />
      <path d="M17.3808 6.1169H15.0992V3.875L17.3808 6.03831V6.1169Z" fill="black" style={{ fill: 'black' }} />
      <path
        d="M15.5712 4.96427L16.3034 5.65858H15.5712V4.96427ZM15.2761 3.40234H14.6272V6.57638H17.8526V5.84547L15.2761 3.40234Z"
        fill="black"
        style={{ fill: 'black' }}
      />
      <path
        d="M20.5421 17.4015H3.47028V10.701L4.34037 9.85547V10.9195H19.6724V9.85547L20.5421 10.701V17.4015Z"
        fill="#ED1C24"
        style={{ fill: '#ED1C24' }}
      />
      <path
        d="M20.0559 10.7574L20.159 10.8576V17.0309H3.85398V10.8576L3.95705 10.7574V11.2944H20.0556V10.7574M19.2886 8.95703V10.5487H4.72354V8.95703L3.08643 10.5487V17.7763H20.9255V10.5487L19.2886 8.95703Z"
        fill="#ED1C24"
        style={{ fill: '#ED1C24' }}
      />
      <path
        d="M5.75763 16.6671V11.8945H7.55505C7.82189 11.8945 8.07615 11.9215 8.31782 11.9753C8.55949 12.0242 8.77095 12.1124 8.9522 12.2396C9.13346 12.362 9.27695 12.526 9.38268 12.7316C9.49344 12.9323 9.54882 13.1819 9.54882 13.4805C9.54882 13.7742 9.49344 14.0263 9.38268 14.2368C9.27695 14.4472 9.13346 14.621 8.9522 14.7581C8.77095 14.8902 8.56201 14.9906 8.32537 15.0591C8.08874 15.1227 7.84203 15.1546 7.58526 15.1546H7.0566V16.6671H5.75763ZM7.0566 14.156H7.52484C7.78161 14.156 7.97042 14.0973 8.09125 13.9798C8.21712 13.8623 8.28006 13.6959 8.28006 13.4805C8.28006 13.2651 8.21209 13.1134 8.07615 13.0253C7.94021 12.9372 7.74637 12.8931 7.49463 12.8931H7.0566V14.156Z"
        fill="white"
        style={{ fill: 'white' }}
      />
      <path
        d="M10.3494 16.6671V11.8945H11.7994C12.172 11.8945 12.5118 11.9386 12.8189 12.0267C13.1311 12.1148 13.3979 12.2543 13.6195 12.4452C13.841 12.6361 14.0122 12.8809 14.133 13.1795C14.2589 13.478 14.3218 13.8378 14.3218 14.2588C14.3218 14.6797 14.2614 15.042 14.1406 15.3455C14.0197 15.6489 13.8485 15.8986 13.627 16.0944C13.4105 16.2902 13.1512 16.4346 12.8491 16.5276C12.5521 16.6206 12.2273 16.6671 11.8749 16.6671H10.3494ZM11.6483 15.6538H11.7239C11.9001 15.6538 12.0662 15.6343 12.2223 15.5951C12.3784 15.551 12.5118 15.4776 12.6226 15.3748C12.7384 15.272 12.829 15.1301 12.8945 14.949C12.9599 14.7679 12.9926 14.5378 12.9926 14.2588C12.9926 13.9798 12.9599 13.7522 12.8945 13.5759C12.829 13.3997 12.7384 13.2627 12.6226 13.1648C12.5118 13.0669 12.3784 13.0008 12.2223 12.9665C12.0662 12.9274 11.9001 12.9078 11.7239 12.9078H11.6483V15.6538Z"
        fill="white"
      />
      <path
        d="M15.1981 16.6671V11.8945H18.4002V12.9518H16.4971V13.8623H18.1284V14.9196H16.4971V16.6671H15.1981Z"
        fill="white"
        style={{ fill: 'white' }}
      />
    </g>
    <defs>
      <clipPath id="clip0_6167_12040">
        <rect width="18" height="20" fill="white" transform="translate(3 2)" style={{ fill: 'white' }} />
      </clipPath>
    </defs>
  </svg>
);
