import React from 'react';

export const ChatWidgetLink = ({ children, label, attributeName, attributeValue, className }) => {
  const dynamicAttribute = attributeName && attributeValue ? { [attributeName]: attributeValue } : {};
  const openChat = () => {
    if (!window?.fcWidget?.isOpen?.()) {
      window?.fcWidget?.open?.();
    }
  };

  return (
    <span onClick={openChat} className={className} title={label} {...dynamicAttribute}>
      {children}
    </span>
  );
};
