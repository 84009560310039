import { Country } from 'types/common.types';

type PathsConfig = { [pageId: string]: { storyblokSlug: string | Record<Country, string> } };

export function mapStoryblokSlugToPageId(config: PathsConfig) {
  return Object.entries(config).reduce((result, [pageId, value]) => {
    if (typeof value.storyblokSlug === 'string') {
      result[value.storyblokSlug] = pageId;
    } else {
      for (const [country, storyblokSlug] of Object.entries(value.storyblokSlug)) {
        result[storyblokSlug] = pageId;
      }
    }

    return result;
  }, {} as Record<string, string>);
}
