export const WasherIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.59961 2.47619C3.59961 2.07513 3.92474 1.75 4.3258 1.75H19.5639C19.965 1.75 20.2901 2.07513 20.2901 2.47619V21.5238C20.2901 21.9249 19.965 22.25 19.5639 22.25H4.3258C3.92474 22.25 3.59961 21.9249 3.59961 21.5238V2.47619ZM5.05199 3.20238V20.7976H18.8377V3.20238H5.05199ZM5.98056 4.85714C5.98056 4.45608 6.30569 4.13095 6.70675 4.13095H17.1829C17.584 4.13095 17.9091 4.45608 17.9091 4.85714C17.9091 5.25821 17.584 5.58333 17.1829 5.58333H6.70675C6.30569 5.58333 5.98056 5.25821 5.98056 4.85714ZM5.50437 12.9524C5.50437 9.3954 8.38787 6.5119 11.9448 6.5119C15.5018 6.5119 18.3853 9.3954 18.3853 12.9524C18.3853 16.5094 15.5018 19.3929 11.9448 19.3929C8.38787 19.3929 5.50437 16.5094 5.50437 12.9524ZM6.96066 12.753C6.977 12.6959 7.00066 12.6399 7.03196 12.5863C7.23413 12.2399 7.67882 12.123 8.0252 12.3252C9.08993 12.9467 10.4439 12.9557 11.5946 12.3174C13.1776 11.4392 15.0011 11.439 16.5831 12.3175C16.7587 12.415 16.8781 12.5747 16.9291 12.7538C16.8248 10.091 14.6332 7.96429 11.9448 7.96429C9.25677 7.96429 7.06531 10.0906 6.96066 12.753ZM16.929 13.1517C16.9141 13.204 16.893 13.2554 16.8654 13.3049C16.6707 13.6556 16.2287 13.782 15.878 13.5873C14.7347 12.9524 13.4441 12.9522 12.2991 13.5874C10.7218 14.4624 8.8212 14.4715 7.29306 13.5796C7.12438 13.4811 7.01012 13.3251 6.96063 13.151C7.06491 15.8138 9.25652 17.9405 11.9448 17.9405C14.6329 17.9405 16.8244 15.8141 16.929 13.1517Z"
      fill="black"
    />
  </svg>
);
