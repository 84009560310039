import React, { createContext, useState, useContext } from 'react';

export const SheetContext = createContext();

export const SheetContextProvider = ({ children }) => {
  const [sheetBody, setSheetBody] = useState(null);
  const [isOpen, setIsOpen] = useState();

  const sheetContext = {
    isOpen,
    setIsOpen,
    sheetBody,
    setSheetBody,
  };

  return (
    <SheetContext.Provider
      value={{
        sheetContext,
      }}
    >
      {children}
    </SheetContext.Provider>
  );
};

export const useSheetContext = () => {
  return useContext(SheetContext);
};
