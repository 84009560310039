import React from 'react';

import { Link } from '@components/Link/Link';

import style from './TextWithLink.module.scss';

export const TextWithLink = ({ text, link, pretext }) => {
  return (
    <p>
      {pretext}
      <Link {...link?.[0]} className={style.textWithLink}>
        {text}
      </Link>
    </p>
  );
};
