import React from 'react';

import cn from 'classnames';
import Link from 'next/link';
import { DataAttribute } from 'types/data-attributes';

import Loader from '@components/Loader/Loader';

import styles from './BaseButton.module.scss';
import { ButtonThemeType } from '../types/theme.type';

interface CommonProps {
  children?: React.ReactNode;
  isLoading?: boolean;
  isFullWidth?: boolean;
  isElevated?: boolean;
  dataAttribute?: DataAttribute;
  theme?: ButtonThemeType;
  size?: 'tiny' | 'default';
  as: 'anchor' | 'button';
  coloredIcon?: boolean;
}

export type BaseButtonProps = CommonProps & { as: 'button' } & React.ButtonHTMLAttributes<HTMLButtonElement>;

export type BaseLinkProps = CommonProps & {
  as: 'anchor';
  href: string;
  target?: '_self' | '_blank';
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export type BaseProps = BaseButtonProps | BaseLinkProps;

export const BaseButton = ({
  children,
  className,
  isLoading,
  isFullWidth,
  dataAttribute,
  theme = 'primary',
  as = 'anchor',
  isElevated,
  size = 'default',
  coloredIcon,
  ...rest
}: BaseProps) => {
  const isBackButton = typeof children === 'string' && children.includes('<');

  // Possible if dataAttribute comes from Storyblok
  if (typeof dataAttribute === 'string') {
    dataAttribute = { 'data-test-id': dataAttribute };
  }

  if (as === 'button') {
    const { type, disabled, onClick } = rest as BaseButtonProps;

    return (
      <button
        type={type === 'submit' ? 'submit' : 'button'}
        disabled={disabled || isLoading}
        className={cn(styles.container, styles[`theme-${theme}`], className, {
          [styles.fullWidth]: isFullWidth,
          [styles.isLoading]: isLoading,
          [styles.elevated]: isElevated,
          [styles.tiny]: size === 'tiny',
          [styles.noLeftPadding]: isBackButton,
          [styles.coloredIcon]: coloredIcon,
        })}
        onClick={onClick}
        aria-label={rest['aria-label'] || undefined}
        {...dataAttribute}
      >
        {isLoading ? <Loader className={cn(styles.loader, { [styles.hideElement]: !isLoading })} /> : children}
      </button>
    );
  }

  const { href, target, onClick } = rest as BaseLinkProps;

  return (
    <Link
      href={href || '#'}
      target={target || '_self'}
      className={cn(styles.container, styles[`theme-${theme}`], className, {
        [styles.fullWidth]: isFullWidth,
        [styles.isLoading]: isLoading,
        [styles.elevated]: isElevated,
        [styles.tiny]: size === 'tiny',
        [styles.noLeftPadding]: isBackButton,
      })}
      aria-label={rest['aria-label'] || undefined}
      onClick={onClick}
      {...dataAttribute}
    >
      {isLoading ? <Loader className={cn(styles.loader, { [styles.hideElement]: !isLoading })} /> : children}
    </Link>
  );
};
