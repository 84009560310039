import {
  ArrowRightIcon,
  CalendarIcon,
  CancelCalendarIcon,
  CategoriesIcon,
  CheckmarkIcon,
  CloseIcon,
  CoffeeMachineIcon,
  CompareIcon,
  DealsIcon,
  DeliveryIcon,
  DishwasherIcon,
  DryerIcon,
  FridgeIcon,
  FreezerIcon,
  HamburgerIcon,
  PDFIcon,
  PaperIcon,
  PointerUpIcon,
  RepairIcon,
  SettingsIcon,
  ShoppingCartIcon,
  StoveOvenIcon,
  StoveIcon,
  UserIcon,
  EyeIcon,
  ShareIcon,
  TrashIcon,
  PaymentIcon,
  PlantIcon,
  PowerToolsIcon,
  ArrowLeftIcon,
  WasherIcon,
  WasherDryerIcon,
  VacuumCleanerIcon,
} from '@base-components/Icons';

export type Icon = {
  icon: keyof typeof availableButtonIcons;
};

export const availableButtonIcons = {
  arrowRight: <ArrowRightIcon />,
  arrowLeft: <ArrowLeftIcon />,
  calendar: <CalendarIcon />,
  cancelCalendar: <CancelCalendarIcon />,
  categories: <CategoriesIcon />,
  checkmark: <CheckmarkIcon />,
  close: <CloseIcon />,
  coffeeMachine: <CoffeeMachineIcon />,
  compare: <CompareIcon />,
  deals: <DealsIcon />,
  delivery: <DeliveryIcon />,
  dishwasher: <DishwasherIcon />,
  dryer: <DryerIcon />,
  fridge: <FridgeIcon />,
  freezer: <FreezerIcon />,
  hamburger: <HamburgerIcon />,
  pdf: <PDFIcon />,
  paper: <PaperIcon />,
  plant: <PlantIcon />,
  repair: <RepairIcon />,
  settings: <SettingsIcon />,
  shoppingCart: <ShoppingCartIcon />,
  stoveOven: <StoveOvenIcon />,
  stove: <StoveIcon />,
  user: <UserIcon />,
  eye: <EyeIcon />,
  share: <ShareIcon />,
  trash: <TrashIcon />,
  payment: <PaymentIcon />,
  pointerUp: <PointerUpIcon />,
  powerTools: <PowerToolsIcon />,
  washer: <WasherIcon />,
  washerDryer: <WasherDryerIcon />,
  vacuum: <VacuumCleanerIcon />,
  none: null,
};
