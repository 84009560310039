import React from 'react';

import Link from 'next/link';

import { useLocale } from '@hooks/useLocale';
import { buildCategoryPath } from '@utils/paths/build-paths';

export const CategoryLink = ({ children, category, label, className, dataAttribute = {} }) => {
  const { locale } = useLocale();

  const href = buildCategoryPath({ locale, category });

  return (
    <>
      <Link
        href={href}
        onClick={e => {
          e.preventDefault();
          window.location.href = href;
        }}
        className={className}
        {...dataAttribute}
      >
        {children || { label }}
      </Link>
    </>
  );
};
