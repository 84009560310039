import React from 'react';

import { getLinkTarget } from '@helpers/get-link-target';

export const ExternalLink = ({ children, link, label, target, dataAttribute = {}, className }) => {
  return (
    <a href={link} className={className} title={label} target={target || getLinkTarget(link)} {...dataAttribute}>
      {children || label}
    </a>
  );
};
