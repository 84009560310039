/* eslint-disable import/no-cycle */
import React, { useState } from 'react';

import cn from 'classnames';

import CheckmarkIcon from '@assets/icons/new/checkmark.svg';
import ClockIcon from '@assets/icons/new/clock.svg';
import CloseIcon from '@assets/icons/new/close.svg';
import DissatisfiedBlackIcon from '@assets/icons/new/dissatisfiedBlack.svg';
import DissatisfiedWhiteIcon from '@assets/icons/new/dissatisfiedWhite.svg';
import InfoIcon from '@assets/icons/new/info.svg';
import NotificationIcon from '@assets/icons/new/notification.svg';
import TruckIcon from '@assets/icons/new/truck.svg';
import WarningIcon from '@assets/icons/new/warning.svg';
import { RichText } from '@components/RichText/RichText';

import styles from './NotificationBar.module.scss';
import { Image } from '../Image/Image';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';

const icons = {
  info: <InfoIcon />,
  warning: <NotificationIcon />,
  error: <WarningIcon />,
  delivery: <TruckIcon />,
  dissatisfiedWhite: <DissatisfiedWhiteIcon />,
  dissatisfiedBlack: <DissatisfiedBlackIcon />,
  checkmark: <CheckmarkIcon />,
  clock: <ClockIcon />,
};

const variantOptions = {
  textWhite: ['filledBlue'],
  textMedium: ['filledBlue', 'filledYellow'],
  titleSubheading: ['filledYellow', 'filledBlue'],
  additionalMarginBottom: ['filledYellow', 'filledBlue'],
  clockYellow: ['filledYellow'],
};

const defaultIcon = {
  delivery: icons.delivery,
  error: icons.error,
  info: icons.info,
  alice: icons.info,
  success: icons.checkmark,
  warning: icons.warning,
  filledBlue: icons.dissatisfiedWhite,
  filledYellow: icons.dissatisfiedBlack,
  clockYellow: icons.clock,
  checkoutDelivery: icons.delivery,
  gold: icons.clock,
};

export const NotificationBar = ({
  title = '',
  content,
  variant,
  shadow = 'none',
  customIcon = undefined,
  showCloseButton = false,
  onClose = null,
  contentVariables = {},
  className = '',
  children = undefined,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const isForOption = option => variantOptions[option]?.includes(variant);
  const isTitle = !!title;

  const handleClose = () => {
    setIsOpen(false);

    if (onClose) {
      onClose();
    }
  };

  return (
    <div
      className={cn(styles.container, className, styles[variant], {
        [styles.shadowGrounded]: shadow === 'grounded',
        [styles.shadowElevated]: shadow === 'elevated',
        [styles.hidden]: !isOpen,
      })}
    >
      <div
        className={cn(styles.content, {
          [styles.contentWithTitle]: isTitle,
        })}
      >
        <span
          className={cn(styles.header, {
            [styles.headerWithTitle]: isTitle,
            [styles.headerForElevated]: shadow === 'elevated',
            [styles.additionalMarginBottom]: isForOption('additionalMarginBottom') && isTitle,
          })}
        >
          <span className={styles.iconContainer}>
            {(customIcon?.filename &&
              Image({ image: customIcon, style: { width: '24px', height: '24px', objectFit: 'fill' } })) ||
              defaultIcon[variant]}
          </span>
          {isTitle && (
            <span
              className={cn(styles.titleText, {
                [styles.textWhite]: isForOption('textWhite'),
                [styles.titleSubheading]: isForOption('titleSubheading'),
              })}
            >
              {title}
            </span>
          )}
        </span>
        <div
          className={cn(styles.text, {
            [styles.contentText]: variant === 'grounded',
            [styles.contentTextWithTitle]: isTitle,
            [styles.additionalMarginLeft]: variant === 'delivery',
          })}
        >
          <RichText
            document={content}
            contentVariables={contentVariables}
            className={cn({
              [styles.textMedium]: isForOption('textMedium'),
              [styles.textWhite]: isForOption('textWhite'),
            })}
          />
          {children}
        </div>
      </div>
      {showCloseButton && (
        <Button
          as="button"
          variant={ButtonVariant.Icon}
          icon="close"
          theme={ButtonTheme.RawOnLight}
          onClick={handleClose}
          className={styles.closeButton}
          size="tiny"
        />
      )}
    </div>
  );
};
