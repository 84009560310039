import React from 'react';

import ArrowIcon from '@assets/icons/new/arrow3.svg';

import styles from './BackButton.module.scss';

export const BackButton = ({ text, onClick }) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <ArrowIcon />

      <button type="button" className={styles.backButton}>
        <span>{text}</span>
      </button>
    </div>
  );
};
