import React from 'react';

import cn from 'classnames';

import styles from './RawButton.module.scss';

export const RawButton = ({ text, onClick, disabled, children, className }) => {
  return (
    <button type="button" className={cn(styles.button, className)} onClick={onClick} disabled={disabled}>
      {children || text}
    </button>
  );
};
