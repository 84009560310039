import { BaseButton, BaseProps } from '../BaseButton/BaseButton';
import { IconButton, IconButtonProps } from '../IconButton/IconButton';
import { ImageButton, ImageButtonProps } from '../ImageButton/ImageButton';
import { ButtonVariant } from '../models/ButtonVariant.enum';

export type ButtonProps = IconButtonProps | ImageButtonProps | (BaseProps & { variant: ButtonVariant.Basic });

export const Button = (props: ButtonProps) => {
  if (!('variant' in props) || props.variant === ButtonVariant.Basic) {
    return <BaseButton {...props} />;
  }

  if (props.variant === ButtonVariant.Icon) {
    return <IconButton {...props} />;
  }

  if (props.variant === ButtonVariant.Image) {
    return <ImageButton {...props} />;
  }

  return <p>Not defined button</p>;
};
