import React from 'react';

import { useRouter } from 'next/router';

import { useRawSubscriptionById } from '@hooks/subscriptions/useRawSubscriptionById';
import { useHttpService } from '@hooks/useHttpService';

import styles from './CancellationSetReminderButton.module.scss';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';

export const CancellationSetReminderButton = ({ text, confirmationText }) => {
  const router = useRouter();
  const httpService = useHttpService();

  const subscriptionId = router.query.id;
  const {
    data: subscription,
    isLoading: isSubscriptionsLoading,
    refetch: refetchSubscription,
  } = useRawSubscriptionById({
    subscriptionId,
  });

  const setReminder = async () => {
    await httpService.post(`/subscription/cancellation-reminder/${subscriptionId}`);

    refetchSubscription();
  };

  const isCancellationReminderSet = subscription?.cancellationReminder === true;

  return (
    <Button
      as="button"
      theme={ButtonTheme.White}
      isElevated
      className={styles.container}
      disabled={isCancellationReminderSet}
      variant={ButtonVariant.Icon}
      arrowPosition="none"
      icon={isCancellationReminderSet ? 'checkmark' : 'none'}
      onClick={setReminder}
      isLoading={isSubscriptionsLoading}
    >
      {isCancellationReminderSet ? confirmationText : text}
    </Button>
  );
};
