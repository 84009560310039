export const DishwasherIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.7909 7.61538H10.1192C10.1291 7.63262 10.1386 7.65022 10.1476 7.66817L10.5218 8.41664H13.3883L13.7626 7.66817C13.7715 7.65022 13.781 7.63262 13.7909 7.61538ZM14.0064 9.41664H9.90379L9.25316 8.11538H4.5V20.4615H19.4103V8.11538H14.657L14.0064 9.41664ZM19.4103 3.5V6.61538H4.5V3.5H19.4103ZM5.30128 5.8141H6.30128V4.30128H5.30128V5.8141ZM7.35256 5.8141H8.35256V4.30128H7.35256V5.8141ZM9.40385 5.8141H10.4038V4.30128H9.40385V5.8141ZM3.75 2C3.33579 2 3 2.33579 3 2.75V21.2115C3 21.6258 3.33579 21.9615 3.75 21.9615H20.1603C20.5745 21.9615 20.9103 21.6258 20.9103 21.2115V2.75C20.9103 2.33579 20.5745 2 20.1603 2H3.75Z"
      fill="black"
    />
  </svg>
);
