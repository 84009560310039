export const DeliveryIcon = ({ ariaHidden = true }) => (
  <svg
    aria-hidden={ariaHidden}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.78782 5.5C2.66376 5.5 1.75 6.41376 1.75 7.53782V14.651C1.75 15.5389 2.3199 16.2933 3.11161 16.5725C3.42523 17.7795 4.52108 18.6749 5.82699 18.6749C7.09043 18.6749 8.1582 17.8368 8.51006 16.6888H13.4832C13.8353 17.8367 14.9031 18.6749 16.1666 18.6749C17.43 18.6749 18.4978 17.8368 18.8496 16.6888H21.5707C22.0842 16.6888 22.5 16.2727 22.5 15.7595V12.1606C22.5 12.0134 22.4649 11.8676 22.3976 11.7363L20.55 8.13733C20.3908 7.82722 20.0715 7.63247 19.7232 7.63247H17.0958V7.53782C17.0958 6.41376 16.1821 5.5 15.058 5.5H3.78782ZM3.60873 7.538C3.60873 7.43952 3.68952 7.35873 3.788 7.35873H15.0582C15.1567 7.35873 15.2375 7.43952 15.2375 7.538V13.2203C14.4722 13.4895 13.8592 14.0801 13.5599 14.8304H8.43383C8.02092 13.7952 7.01028 13.0597 5.82717 13.0597C4.92501 13.0597 4.12225 13.4869 3.60873 14.149V7.538ZM17.0958 11.2315V9.4912H19.1557L20.0491 11.2315H17.0958ZM17.0958 13.2203V13.0899H20.6415V14.8304H18.7732C18.4739 14.0801 17.861 13.4895 17.0958 13.2203ZM4.87783 15.8674C4.87783 15.3444 5.30405 14.9183 5.82717 14.9183C6.35001 14.9183 6.77614 15.3443 6.77614 15.8674C6.77614 16.3905 6.35001 16.8166 5.82717 16.8166C5.30405 16.8166 4.87783 16.3904 4.87783 15.8674ZM15.2172 15.8674C15.2172 15.3445 15.6436 14.9183 16.1666 14.9183C16.6894 14.9183 17.1155 15.3443 17.1155 15.8674C17.1155 16.3905 16.6894 16.8166 16.1666 16.8166C15.6436 16.8166 15.2172 16.3904 15.2172 15.8674Z"
      fill="black"
    />
  </svg>
);
