import React from 'react';

import cn from 'classnames';

import styles from './Loader.module.scss';

const Loader = ({ className, variant = 'dark' }) => {
  return (
    <div className={cn(styles.container, className)} data-loader="loader">
      <div className={cn(styles.loader, styles[`loader-${variant}`])} data-color />
      <div className={cn(styles.firstDelay, styles.loader)} />
      <div className={cn(styles.secondDelay, styles.loader)} />
      <div className={cn(styles.thirdDelay, styles.loader)} />
    </div>
  );
};

export default Loader;
