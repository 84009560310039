export const CompareIcon = ({ ariaHidden = true }) => (
  <svg
    aria-hidden={ariaHidden}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.92685 2C9.65855 2 9.43909 2.21867 9.43909 2.48598V6.49579C9.43909 6.76311 9.65856 6.98177 9.92685 6.98177C10.1952 6.98177 10.4146 6.7631 10.4146 6.49579V2.48598C10.4146 2.21866 10.1951 2 9.92685 2Z"
      fill="black"
    />
    <path
      d="M7.00003 9.82506C7.00003 9.55774 6.78056 9.33908 6.51227 9.33908H2.48776C2.21946 9.33908 2 9.55775 2 9.82506C2 10.0924 2.21947 10.311 2.48776 10.311H6.51209C6.7804 10.311 7.00003 10.1166 7.00003 9.82506Z"
      fill="black"
    />
    <path
      d="M7.19522 6.4471L5.90253 5.15913C5.70739 4.9647 5.41477 4.9647 5.2196 5.15913C5.02446 5.35356 5.02446 5.64511 5.2196 5.83957L6.51229 7.12754C6.60978 7.22467 6.73177 7.27332 6.85375 7.27332C6.97573 7.27332 7.09772 7.22467 7.19521 7.12754C7.39018 6.93311 7.39018 6.61712 7.19521 6.4471H7.19522Z"
      fill="black"
    />
    <path
      d="M13.244 7.15186L14.5367 5.86389C14.7318 5.66946 14.7318 5.37791 14.5367 5.18345C14.3415 4.98902 14.0489 4.98902 13.8537 5.18345L12.561 6.47142C12.3659 6.66586 12.3659 6.95741 12.561 7.15186C12.6585 7.24899 12.7805 7.29764 12.9025 7.29764C13.0245 7.29764 13.1463 7.24916 13.2439 7.15186H13.244Z"
      fill="black"
    />
    <path
      d="M7.1464 12.5468C6.95125 12.3524 6.65863 12.3524 6.46346 12.5468L5.17077 13.8348C4.97563 14.0292 4.97563 14.3208 5.17077 14.5152C5.26826 14.6124 5.39025 14.661 5.51223 14.661C5.63422 14.661 5.7562 14.6124 5.85369 14.5152L7.14638 13.2273C7.34152 13.0328 7.34152 12.717 7.14638 12.5468H7.1464Z"
      fill="black"
    />
    <path
      d="M12.4148 19.8129L14.2927 16.2406L20.0976 22L22.0001 20.1045L16.1951 14.3208L19.8051 12.4495L9.60986 9.58188L12.4148 19.8129Z"
      fill="black"
    />
  </svg>
);
