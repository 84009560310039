import React from 'react';

import { isEmpty } from 'lodash';

const templateSplit = new RegExp(/({.*?})/g);

export const interpolateContent = (contentString = '', contentVariables = {}) => {
  if (!isEmpty(contentVariables) && Object.values(contentVariables).every(variable => typeof variable === 'string')) {
    return Object.entries(contentVariables).reduce((acc, [key, value]) => {
      return acc.split(`{${key}}`).join(value);
    }, contentString);
  }

  const allContentVariables = { ...contentVariables, linebreak: <br /> };

  const splittedText = contentString.split(templateSplit);

  const componentsArray = splittedText.map((sentence, index) => {
    const hasBrackets = templateSplit.test(sentence);
    const sentenceWithoutBrackets = hasBrackets ? sentence.slice(1, -1) : sentence;

    const selectedVariable = allContentVariables[sentenceWithoutBrackets];

    if (!selectedVariable) {
      return sentence;
    }

    if (typeof selectedVariable === 'string' || typeof selectedVariable === 'number') {
      return selectedVariable;
    }
    // it's safe to put key as index here because essentially it does not matter
    // because this content isn't going to be changed (this is just text)
    return <React.Fragment key={index}>{selectedVariable}</React.Fragment>;
  });

  const accumulatedContent = componentsArray.reduce((acc, next) => {
    const accLength = acc.length;
    const lastIndex = Math.max(acc.length - 1, 0);

    if (accLength === 0) {
      return [next];
    }

    if (typeof acc[lastIndex] === 'string' && (typeof next === 'string' || typeof next === 'number')) {
      const addedString = acc[lastIndex] + next;
      const accCopy = [...acc];
      accCopy[lastIndex] = addedString;

      return accCopy;
    }

    return [...acc, next];
  }, []);

  return accumulatedContent;
};
