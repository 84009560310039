export const ShoppingCartIcon = ({ ariaHidden = true }) => (
    <svg
        aria-hidden={ariaHidden}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.6909 10H21.0455C21.5455 10 22 10.4711 22 10.9423L21.9727 11.1736L19.5727 18.7493C19.3545 19.4689 18.6545 20 17.8182 20H6C5.16363 20 4.46363 19.4689 4.25454 18.7493L2.03636 11.1736C2.00909 11.0965 2 11.0194 2 10.9423C2 10.4711 2.36364 10 2.86364 10H7.21818L11.2455 4.36835C11.4182 4.11993 11.7091 4 12 4C12.2909 4 12.5818 4.11993 12.7545 4.35978L16.6909 10ZM14.5 10L12 6.38141L9.40909 10H14.5ZM6 18.1154H18L20 12H4L6 18.1154ZM14 15C14 16.1046 13.1046 17 12 17C10.8954 17 10 16.1046 10 15C10 13.8954 10.8954 13 12 13C13.1046 13 14 13.8954 14 15Z"
            fill="black"
        />
    </svg>
);
