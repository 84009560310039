import React from 'react';

import { availableButtonIcons, Icon } from '@base-components/Icons/availableButtonIcons';
import cn from 'classnames';

import styles from './IconButton.module.scss';
import { BaseButton, BaseProps } from '../BaseButton/BaseButton';
import { ButtonVariant } from '../models/ButtonVariant.enum';

export type IconButtonProps = {
  arrowPosition?: 'down' | 'right' | 'none';
  variant: ButtonVariant.Icon;
} & Icon &
  BaseProps;

export const IconButton = ({
  icon = 'none',
  arrowPosition = 'right',
  className,
  ...baseButtonProps
}: IconButtonProps) => {
  const isOnlyIconButton =
    !baseButtonProps.children || (Array.isArray(baseButtonProps.children) && baseButtonProps.children.length === 0);
  const isCorrectIcon = Object.keys(availableButtonIcons).includes(icon) && icon !== 'none';

  return (
    <BaseButton
      {...baseButtonProps}
      className={cn(className, styles.container, {
        [styles.onlyIconContainer]: isOnlyIconButton,
        [styles.noLeftPadding]: icon === 'arrowLeft',
      })}
      aria-label={isOnlyIconButton ? baseButtonProps['aria-label'] : ''}
    >
      {isCorrectIcon && (
        <div className={cn(styles.iconLeft, { [styles.onlyIcon]: isOnlyIconButton })}>{availableButtonIcons[icon]}</div>
      )}
      {baseButtonProps.children}
      {!isOnlyIconButton && arrowPosition !== 'none' && (
        <div
          className={cn(styles.iconRight, {
            [styles.arrowDown]: arrowPosition === 'down',
          })}
        >
          {availableButtonIcons['arrowRight']}
        </div>
      )}
    </BaseButton>
  );
};
