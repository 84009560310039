/* eslint-disable import/no-cycle */
import React from 'react';

import cn from 'classnames';

import styles from './TitleBlock.module.scss';
import { RichText } from '../RichText/RichText';

export const TitleBlock = ({ title, text, additionalPadding = false, contentVariables }) => {
  return (
    <div>
      <div className={cn(styles.title, { [styles.additionalPadding]: additionalPadding })}>{title}</div>

      <RichText document={text} contentVariables={contentVariables} />
    </div>
  );
};
