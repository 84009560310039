import StoryblokClient from 'storyblok-js-client';

import { storyblok, environmentName } from '../config/server';

const Storyblok = new StoryblokClient({
  ...storyblok,
  cache: {
    clear: 'auto',
    type: ['aws-prod', 'aws-qa', 'aws-dev'].includes(environmentName) ? 'memory' : 'none',
    rateLimit: 20,
  },
});

export default Storyblok;
