export const VacuumCleanerIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.93051 16.0673L7.93298 16.0687L12.6191 7.95207C12.3202 7.77948 12.2177 7.39722 12.3903 7.09829L13.8903 4.50021C13.8918 4.49774 13.8932 4.49527 13.8947 4.49283C13.9008 4.48258 13.9071 4.47257 13.9137 4.46279L13.9124 4.46206C13.9124 4.46209 13.9124 4.46203 13.9124 4.46206C13.9304 4.43095 13.9488 4.40007 13.9675 4.36961C14.1262 4.11126 14.3119 3.8763 14.5193 3.66703C15.4374 2.74065 16.7812 2.3179 18.1002 2.59916L19.569 2.91235C20.6482 3.14249 21.1891 4.35844 20.6374 5.31412L19.3251 7.58706L19.3199 7.5958L19.3133 7.60666C19.31 7.61288 19.3065 7.61906 19.303 7.62521L17.803 10.2233C17.6304 10.5222 17.2482 10.6246 16.9492 10.4521L13.9182 8.70207L9.23066 16.8211L8.38263 16.3315L9.22409 16.8231L9.08828 17.3862C9.26479 17.4297 9.4167 17.549 9.49942 17.7168L10.5279 19.8038C10.6234 19.9975 10.6121 20.2268 10.498 20.4102C10.3839 20.5936 10.1833 20.7051 9.96731 20.7051L3.97447 20.7051C3.63142 20.7051 3.35249 20.4286 3.3495 20.0855C3.34909 20.0384 3.35391 19.9924 3.36343 19.9481C3.64935 18.4954 4.91099 17.4383 6.39265 17.4125L7.47518 17.3936L7.93051 16.0673ZM4.90652 19.4551C5.24663 18.9791 5.79904 18.673 6.41447 18.6623L8.55341 18.6249L8.96252 19.4551L4.90652 19.4551ZM15.3721 4.58312C15.6326 4.31 15.9465 4.0983 16.2902 3.95849C16.7699 3.76337 17.3077 3.70827 17.8395 3.82168L19.3083 4.13487C19.5573 4.18798 19.6822 4.46858 19.5548 4.68912L18.555 6.42079L15.3721 4.58312ZM9.20172 17.8635L9.29143 17.8193L9.20172 17.8635ZM13.7854 7.18202L14.6604 5.66648L17.908 7.54148L17.033 9.05702L13.7854 7.18202Z"
      fill="black"
    />
  </svg>
);
