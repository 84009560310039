import React, { useState } from 'react';

import cn from 'classnames';
import ReactPlayer from 'react-player/lazy';

import PlayButton from '@assets/icons/play-button.svg';

import styles from './VideoPlayer.module.scss';

export const VideoPlayer = ({
  video,
  className,
  fullWidth = false,
  youtubeLink,
  videoFile,
  videoThumbnail,
  controls,
}) => {
  const isControls = video?.controls || controls || false;
  const [isPlaying, setIsPlaying] = useState(false);
  const videoUrl = video?.youtubeLink || video?.videoFile?.filename || youtubeLink || videoFile?.filename;
  const thumbnail = video?.videoThumbnail?.filename || !!video?.youtubeLink || videoThumbnail?.filename;

  const handlePreview = () => {
    setIsPlaying(true);
  };

  return (
    <div className={cn(className, styles.playerWrapper, { [styles.fullWidth]: fullWidth })}>
      {videoUrl && (
        <ReactPlayer
          className={styles.player}
          url={videoUrl}
          controls={isControls}
          light={thumbnail}
          onClickPreview={handlePreview}
          playing={isPlaying}
          playIcon={<PlayButton />}
          loop
        />
      )}
    </div>
  );
};
