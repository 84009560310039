import { useQuery } from 'react-query';

import { useContent } from '@hooks/useContent';
import { useHttpService } from '@hooks/useHttpService';
import { addCMSFieldsToSubscriptions } from '@utils/add-cms-fields-to-subscriptions';

import { useLocale } from '../useLocale';

const useRawSubscriptionByIdQueryId = 'useRawSubscriptionByIdQueryId';

export const useRawSubscriptionById = ({ subscriptionId }) => {
  const httpService = useHttpService();
  const { language, country } = useLocale();
  const { data: plansContent } = useContent({
    locale: `${country}-${language}`,
    name: `plans-${country}`,
  });

  return useQuery(
    useRawSubscriptionByIdQueryId,
    async () => {
      if (subscriptionId) {
        const { data } = await httpService.get(`/subscription/${subscriptionId}`);

        return data;
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
      select: data => addCMSFieldsToSubscriptions({ subscriptions: [data] }, plansContent)?.subscriptions[0],
    }
  );
};
