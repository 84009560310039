export enum ButtonTheme {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  White = 'white',
  RawOnLight = 'raw-on-light',
  RawOnDark = 'raw-on-dark',
  Green = 'green',
  SoftBlue = 'soft-blue',
}

export type ButtonThemeType = `${ButtonTheme}`;

export const possibleButtonThemes = [
  'primary',
  'secondary',
  'tertiary',
  'white',
  'raw-on-light',
  'raw-on-dark',
  'green',
  'soft-blue',
];
