export const CheckmarkReverseIcon = ({ ariaHidden = true }) => (
    <svg
        aria-hidden={ariaHidden}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_712_45945)">
            <path
                d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM6.4 12.4L2.4 8.4L3.528 7.272L6.4 10.136L12.136 4.4L13.268 5.536L6.4 12.4Z"
                fill="#17850F"
            />
            <path
                d="M2.3999 8.40039L6.3999 12.4004L13.2679 5.53639L12.1359 4.40039L6.3999 10.1364L3.5279 7.27239L2.3999 8.40039Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_712_45945">
                <rect width="16" height="16" rx="8" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
