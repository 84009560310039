import React from 'react';

import { AnchorLink } from './AnchorLink';
import { CategoryLink } from './CategoryLink';
import { ChatWidgetLink } from './ChatWidgetLink';
import { DeprecatedLink } from './DeprecatedLink';
import { DownloadLink } from './DownloadLink';
import { ExternalLink } from './ExternalLink';
import { InternalLink } from './InternalLink';
import { SheetLink } from './SheetLink';

const LinkComponents = {
  categoryLink: CategoryLink,
  internalLink: InternalLink,
  externalLink: ExternalLink,
  sheetLink: SheetLink,
  link: DeprecatedLink,
  chatWidgetLink: ChatWidgetLink,
  downloadLink: DownloadLink,
  anchorLink: AnchorLink,
};

export const Link = ({ children, component, className, href, ...rest }) => {
  if (!component) {
    return (
      <a href={href} className={className}>
        {children}
      </a>
    );
  }

  if (component === 'noLink') {
    return <div className={className}>{children}</div>;
  }

  const Component = LinkComponents[component];
  if (typeof Component !== 'undefined') {
    return (
      <Component className={className} {...rest}>
        {children}
      </Component>
    );
  }

  return (
    <p>
      The link <strong>{component}</strong> has not been created yet.
    </p>
  );
};
