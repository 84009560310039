/* eslint-disable import/no-cycle */
import React from 'react';

import cn from 'classnames';

import { RichText } from '@components/RichText/RichText';

import styles from './Item.module.scss';

export const Item = ({ item, isOpened, onClick, allOpened }) => {
  const cursorStyle = { cursor: allOpened ? 'default' : 'pointer' };

  return (
    <div className={styles.item} data-faq-question={item.question}>
      <div className={styles.header} onClick={onClick}>
        <div className={cn(styles.text)} style={cursorStyle}>
          {item.question}
        </div>

        {!allOpened && <div className={styles.plusSign}>{isOpened ? '-' : '+'}</div>}
      </div>

      {isOpened && (
        <div className={styles.content}>
          <RichText document={item.answer} />
        </div>
      )}
    </div>
  );
};
