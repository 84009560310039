export const ArrowLeftIcon = ({ ariaHidden = true }) => (
  <svg
    style={{ transform: 'rotate(180deg)' }}
    aria-hidden={ariaHidden}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.83198 6L8.5 7.41L12.8266 12L8.5 16.59L9.83198 18L15.5 12L9.83198 6Z" />
  </svg>
);
