export const PaperIcon = ({ ariaHidden = true }) => (
  <svg
    aria-hidden={ariaHidden}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="24"
    viewBox="0 0 18 24"
    fill="none"
  >
    <path
      d="M1.00002 22H0.500015H1.00002ZM1.29291 22.7071L1.64646 22.3536H1.64646L1.29291 22.7071ZM1.00002 2H1.50002H1.00002ZM16.8197 18.5728L16.4099 18.2864L16.8197 18.5728ZM14.0247 22.5728L14.4346 22.8592H14.4346L14.0247 22.5728ZM13.205 22V22.5C13.3683 22.5 13.5213 22.4203 13.6148 22.2864L13.205 22ZM16 18L16.4099 18.2864C16.4685 18.2024 16.5 18.1024 16.5 18H16ZM2.00002 22H1.50002C1.50002 22.2761 1.72387 22.5 2.00002 22.5V22ZM2.00002 2V1.5C1.72387 1.5 1.50002 1.72386 1.50002 2H2.00002ZM16 2H16.5C16.5 1.72386 16.2762 1.5 16 1.5V2ZM0.500015 22C0.500015 22.3978 0.65805 22.7794 0.939355 23.0607L1.64646 22.3536C1.55269 22.2598 1.50002 22.1326 1.50002 22H0.500015ZM0.500015 2L0.500015 22H1.50002L1.50002 2H0.500015ZM2.00002 0.5C1.17159 0.5 0.500015 1.17157 0.500015 2H1.50002C1.50002 1.72386 1.72387 1.5 2.00002 1.5V0.5ZM16 0.5H2.00002V1.5H16V0.5ZM17.5 2C17.5 1.17157 16.8284 0.5 16 0.5V1.5C16.2762 1.5 16.5 1.72386 16.5 2H17.5ZM17.5 18V2H16.5V18H17.5ZM17.2296 18.8592C17.4056 18.6072 17.5 18.3073 17.5 18H16.5C16.5 18.1024 16.4685 18.2024 16.4099 18.2864L17.2296 18.8592ZM14.4346 22.8592L17.2296 18.8592L16.4099 18.2864L13.6148 22.2864L14.4346 22.8592ZM13.205 23.5C13.6949 23.5 14.1539 23.2608 14.4346 22.8592L13.6149 22.2864C13.5213 22.4203 13.3683 22.5 13.205 22.5V23.5ZM2.00002 23.5H13.205V22.5H2.00002V23.5ZM0.939355 23.0607C1.22066 23.342 1.60219 23.5 2.00002 23.5V22.5C1.86741 22.5 1.74023 22.4473 1.64646 22.3536L0.939355 23.0607ZM13.6148 22.2864L16.4099 18.2864L15.5902 17.7136L12.7951 21.7136L13.6148 22.2864ZM3.00002 22.5H13.205V21.5H3.00002V22.5ZM2.00002 22.5H3.00002V21.5H2.00002V22.5ZM1.50002 21V22H2.50002V21H1.50002ZM1.50002 3V21H2.50002V3H1.50002ZM1.50002 2V3H2.50002V2H1.50002ZM3.00002 1.5H2.00002V2.5H3.00002V1.5ZM15 1.5H3.00002V2.5H15V1.5ZM16 1.5H15V2.5H16V1.5ZM16.5 3V2H15.5V3H16.5ZM16.5 18V3H15.5V18H16.5Z"
      fill="black"
    />
  </svg>
);
