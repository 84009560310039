import React from 'react';

import { useSheetContext } from '@context/sheet';

export const SheetLink = ({ children, className, dataAttribute = {}, ...sheetProps }) => {
  const { sheetContext } = useSheetContext();

  return (
    <div
      // eslint-disable-next-line react/no-unknown-property
      outsideclickmarker="sheetLink"
      className={className}
      onClick={e => {
        e.stopPropagation();
        sheetContext.setSheetBody({
          uid: sheetProps._uid,
          content: sheetProps.content,
          headingTitle: sheetProps.headingTitle,
          children: sheetProps?.sheetChildren,
        });
      }}
      {...dataAttribute}
    >
      {children}
    </div>
  );
};
