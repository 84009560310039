import React from 'react';

import cn from 'classnames';

import { Image } from '@components/Image/Image';

import styles from './ImageButton.module.scss';
import { BaseButton, BaseProps } from '../BaseButton/BaseButton';
import { ButtonVariant } from '../models/ButtonVariant.enum';

export type ImageButtonProps = {
  image?: { filename: string; fieldtype: string; alt?: string };
  variant: ButtonVariant.Image;
} & BaseProps;

export const ImageButton = ({ image, ...baseButtonProps }: ImageButtonProps) => {
  return (
    <BaseButton {...baseButtonProps} className={cn(baseButtonProps.className, styles.container)}>
      {image?.filename && (
        <div className={styles.logo}>
          <Image alt={image?.alt ? image.alt : 'logo'} image={image} />
        </div>
      )}
      {baseButtonProps.children}
    </BaseButton>
  );
};
