import React from 'react';

import ArrowIcon from '@assets/icons/new/arrow4white.svg';

import styles from './MobileBackButton.module.scss';

export const MobileBackButton = ({ onClick, text }) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <button type="button" className={styles.button}>
        <ArrowIcon />
        <span className={styles.buttonText}>{text}</span>
      </button>
    </div>
  );
};
