import React from 'react';

import cn from 'classnames';

import ArrowIcon from '@assets/icons/new/arrow.svg';
import ArrowBlackIcon from '@assets/icons/new/arrowBlack.svg';

import styles from './ArrowButton.module.scss';

export const ArrowButton = ({
  text,
  onClick,
  isSubmit,
  className,
  mobileFullWidth,
  fullWidth,
  disabled,
  dataAttribute,
  background = 'blueBright',
  children,
  isArrowBlack = false,
}) => {
  return (
    <div
      className={cn(styles.container, className, {
        [styles.fullWidth]: fullWidth,
      })}
    >
      <button
        type={isSubmit ? 'submit' : 'button'}
        className={cn(styles.button, styles[background], {
          [styles.mobileFullWidth]: mobileFullWidth,
          [styles.fullWidth]: fullWidth,
        })}
        onClick={onClick}
        disabled={disabled}
        {...dataAttribute}
      >
        {children || <span className={styles.textField}>{text}</span>}
        {isArrowBlack ? <ArrowBlackIcon className={styles.arrow} /> : <ArrowIcon className={styles.arrow} />}
      </button>
    </div>
  );
};
