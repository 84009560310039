import React, { forwardRef } from 'react';

import { ArrowButton } from './ArrowButton/ArrowButton';
import { BackButton } from './BackButton/BackButton';
import { BigBrandPinkButton } from './BigBrandPinkButton/BigBrandPinkButton';
import { BigButton } from './BigButton/BigButton';
import { BlueButton } from './BlueButton/BlueButton';
import { CommonButton } from './CommonButton/CommonButton';
import { DarkBlueButton } from './DarkBlueButton/DarkBlueButton';
import { DarkBlueSearchButton } from './DarkBlueSearchButton/DarkBlueSearchButton';
import { MobileBackButton } from './MobileBackButton/MobileBackButton';
import { RawBackButton } from './RawBackButton/RawBackButton';
import { RawButton } from './RawButton/RawButton';

export const variants = {
  arrow: ArrowButton,
  big: BigButton,
  bigBrandPink: BigBrandPinkButton,
  blue: BlueButton,
  darkBlue: DarkBlueButton,
  darkBlueSearch: DarkBlueSearchButton,
  back: BackButton,
  mobileBack: MobileBackButton,
  common: CommonButton,
  raw: RawButton,
  rawBack: RawBackButton,
};

//TODO remove this component after storyblok cleanup removing (old) fields
export const DeprecatedButton = forwardRef(function Button(
  {
    variant,
    text,
    onClick,
    isSubmit = false,
    className,
    mobileFullWidth,
    fullWidth,
    disabled = false,
    dataAttribute,
    background,
    children,
    toggleState,
    isArrowBlack = false,
  },
  ref
) {
  const VariantButton = variants[variant] || variants.blue;

  return (
    <VariantButton
      text={text}
      onClick={onClick}
      isSubmit={isSubmit}
      className={className}
      mobileFullWidth={mobileFullWidth}
      fullWidth={fullWidth}
      background={background}
      disabled={disabled}
      dataAttribute={dataAttribute}
      toggleState={toggleState}
      isArrowBlack={isArrowBlack}
    >
      {children}
    </VariantButton>
  );
});
