export const CancelCalendarIcon = ({ ariaHidden = true }) => (
  <svg
    aria-hidden={ariaHidden}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 5V3H6V5H4C3.44772 5 3 5.44772 3 6V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V6C21 5.44772 20.5523 5 20 5H18V3H16V5H8ZM19 9V7H5V9H19ZM5 11V19H19V11H5Z"
      fill="black"
    />
    <path
      d="M15 12.6043L14.3957 12L12 14.3957L9.60429 12L9 12.6043L11.3957 15L9 17.3957L9.60429 18L12 15.6043L14.3957 18L15 17.3957L12.6043 15L15 12.6043Z"
      fill="black"
    />
  </svg>
);
