import React from 'react';

import { Button as NewButton } from '@base-components/Button';
import { ButtonVariant, ButtonThemeType } from '@base-components/Button';
import { availableButtonIcons } from '@base-components/Icons/availableButtonIcons';
import { usePathname } from 'next/navigation';
import { DataAttribute } from 'types/data-attributes';

import { DeprecatedButton, variants } from '@components/Button/Button';
import { Link } from '@components/Link/Link';
import { StoryblokButtonWrapper } from '@components/StoryblokButtonWrapper/StoryblokButtonWrapper';
import { useSheetContext } from '@context/sheet';
import { useLocale } from '@hooks/useLocale';
import { getHrefFromStoryblok } from '@utils/paths/build-paths';

import styles from './StoryblokButton.module.scss';

interface InternalLink {
  label: string;
  pageId: string;
  component: string;
  target?: '_blank' | '_self';
}

interface ExternalLink {
  label: string;
  link: string;
  component: string;
  target?: '_blank' | '_self';
}

interface AnchorLink {
  label: string;
  id: string;
  component: string;
  target?: '_blank' | '_self';
}

interface SheetLink {
  _uid: string;
  content: string;
  headingTitle: string;
  target?: undefined;
}

type StoryblokButtonProps = {
  variant: keyof typeof variants;
  text: string;
  link: [InternalLink | ExternalLink | AnchorLink | SheetLink];
  align?: 'center' | 'left' | 'right';
  mobileFullWidth?: boolean;
  dataAttribute?: DataAttribute;
  background?: 'blueBright' | 'blueDark' | 'blueLight' | 'brandPink';
};

type NewStoryblokButtonProps = {
  text: string;
  link: [InternalLink | ExternalLink | AnchorLink | SheetLink];
  newVariant: ButtonVariant;
  theme: ButtonThemeType;
  isElevated: boolean;
  isFullWidth: boolean;
  align: 'center' | 'left' | 'right';
  icon: keyof typeof availableButtonIcons;
  arrowPosition: 'right' | 'down' | 'none';
  image?: { filename: string; fieldtype: string; alt?: string };
  ariaLabel?: string;
};

export const StoryblokButton = ({
  variant,
  text,
  link,
  align = 'center',
  mobileFullWidth = false,
  dataAttribute,
  background,
  arrowPosition,
  icon,
  isElevated,
  isFullWidth,
  newVariant,
  theme,
  image,
  ariaLabel,
}: StoryblokButtonProps & NewStoryblokButtonProps) => {
  const { locale } = useLocale();
  const pathname = usePathname();
  const { sheetContext } = useSheetContext();

  const isNewButton = Boolean(newVariant);
  const firstLink = link?.[0] || {};
  const pageId = ('pageId' in firstLink ? firstLink.pageId : '') as string;
  const pageAnchorId = ('id' in firstLink ? firstLink.id : '') as string;
  const linkUrl = ('link' in firstLink && firstLink.link) as string;
  const finalUrl = pageAnchorId && pathname ? `${pathname}#${pageAnchorId}` : linkUrl;
  const isLink = Boolean(pageId || linkUrl || pageAnchorId);

  const href = getHrefFromStoryblok({ locale, link: finalUrl, pageId });
  const isDataSheetLink = 'content' in firstLink && 'headingTitle' in firstLink && '_uid' in firstLink;
  const dataSheetProps = isDataSheetLink
    ? {
        onClick: e => {
          e.stopPropagation();
          sheetContext.setSheetBody({
            uid: firstLink._uid,
            content: firstLink.content,
            headingTitle: firstLink.headingTitle,
          });
        },
      }
    : {};

  const buttonDataAttribute = {
    'data-button': 'storyblok-button',
    ...dataAttribute,
    ...(isDataSheetLink && { outsideclickmarker: 'sheetLink' }),
  };

  if (!isNewButton) {
    return (
      <StoryblokButtonWrapper align={align}>
        {/* @ts-ignore */}
        <Link {...link?.[0]}>
          <DeprecatedButton
            // @ts-ignore
            className={styles.button}
            variant={variant}
            background={background}
            text={text}
            mobileFullWidth={mobileFullWidth}
            dataAttribute={buttonDataAttribute}
          />
        </Link>
      </StoryblokButtonWrapper>
    );
  }

  if (isLink && !isDataSheetLink) {
    return (
      <StoryblokButtonWrapper align={align}>
        <NewButton
          as="anchor"
          href={href}
          target={link[0]?.target || '_self'}
          variant={newVariant}
          theme={theme}
          isElevated={isElevated}
          isFullWidth={isFullWidth}
          icon={icon}
          arrowPosition={arrowPosition}
          image={image}
          aria-label={ariaLabel}
          dataAttribute={dataAttribute}
        >
          {text}
        </NewButton>
      </StoryblokButtonWrapper>
    );
  }

  return (
    <StoryblokButtonWrapper align={align}>
      <NewButton
        as="button"
        variant={newVariant}
        theme={theme}
        isElevated={isElevated}
        isFullWidth={isFullWidth}
        icon={icon}
        arrowPosition={arrowPosition}
        image={image}
        aria-label={ariaLabel}
        dataAttribute={dataAttribute}
        {...dataSheetProps}
      >
        {text}
      </NewButton>
    </StoryblokButtonWrapper>
  );
};
