export const PaymentIcon = ({ ariaHidden = true }) => (
  <svg
    aria-hidden={ariaHidden}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.782 16C15.3741 16 15.913 15.7977 16.3154 15.4667C16.5366 15.5524 16.7808 15.6 17.0376 15.6C18.0342 15.6 18.8421 14.8837 18.8421 14C18.8421 13.1163 18.0342 12.4 17.0376 12.4C16.7808 12.4 16.5366 12.4476 16.3154 12.5333C15.913 12.2023 15.3741 12 14.782 12C13.5362 12 12.5263 12.8954 12.5263 14C12.5263 15.1046 13.5362 16 14.782 16Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.05263 5C2.47128 5 2 5.44772 2 6V18C2 18.5523 2.47128 19 3.05263 19H20.9474C21.5287 19 22 18.5523 22 18V6C22 5.44772 21.5287 5 20.9474 5H3.05263ZM4.10526 7V8H19.8947V7H4.10526ZM4.10526 17V10H19.8947V17H4.10526Z"
      fill="black"
    />
  </svg>
);
