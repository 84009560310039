import React from 'react';

import cn from 'classnames';
import { DataAttribute } from 'types/data-attributes';

import ArrowRightIcon from '@assets/icons/new/arrowBlack.svg';
import { Link } from '@components/Link/Link';

import styles from './IconButton.module.scss';
import { Image } from '../../Image/Image';
import Loader from '../../Loader/Loader';

interface IconButtonProps {
  text?: string; // TODO: remove it when we will be sure that text is not used anymore
  label?: string;
  icon: any;
  link: any;
  onClick: any;
  isSubmit: boolean;
  fullWidth?: boolean;
  arrowDirection?: 'right' | 'down' | false;
  dataAttribute?: DataAttribute;
  disabled?: boolean;
  className?: any;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'elevated' | 'raw' | 'rawDark';
  size?: 'medium' | 'small' | undefined;
  isLoading?: boolean;
  noLink?: boolean;
}
//TODO remove this component after storyblok cleanup from iconButton, it is used in RichText and in DetailedSubscriptionCart as button to download pdf
export const DeprecatedIconButton = ({
  text, // TODO: remove it when we will be sure that text is not used anymore
  label,
  icon,
  link,
  onClick,
  isSubmit,
  fullWidth = true,
  arrowDirection = 'right',
  dataAttribute,
  disabled = false,
  className,
  variant = 'elevated',
  size,
  isLoading = false,
  noLink = false,
}: IconButtonProps) => {
  // Possible if dataAttribute comes from Storyblok
  if (typeof dataAttribute === 'string') {
    dataAttribute = { 'data-test-id': dataAttribute };
  }

  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      onClick={onClick}
      className={cn(styles.container, className, {
        [styles.fullWidth]: fullWidth,
        [styles.isLoading]: isLoading,
      })}
      data-icon-button={text || label}
      disabled={disabled}
      {...dataAttribute}
    >
      <Link
        {...link?.[0]}
        component={noLink ? 'noLink' : link?.[0]?.component ?? link?.component ?? ''}
        className={cn(styles.link, { [styles.fullWidth]: fullWidth })}
      >
        <div
          className={cn(styles.btn, styles[variant], {
            [styles.fullWidth]: fullWidth,
            [styles.buttonFixedWidth]: !fullWidth,
            [styles.center]: isLoading,
            [styles[size]]: size,
          })}
        >
          {
            <Loader
              variant={['primary', 'secondary', 'rawDark'].includes(variant) ? 'light' : 'dark'}
              className={cn(styles.loader, { [styles.hideElement]: !isLoading })}
            />
          }
          {icon?.filename && (
            <div className={cn(styles.iconLeft, { [styles.hideElement]: isLoading })}>
              {/* @ts-ignore */}
              <Image alt="icon" image={icon} style={{ width: '24px', height: '24px', objectFit: 'fill' }} />
            </div>
          )}
          <div className={cn(styles.text, { [styles.hideElement]: isLoading })}>{text || label}</div>
          {arrowDirection && (
            <div
              className={cn(styles.iconRight, {
                [styles.rotate]: arrowDirection === 'down',
                [styles.hideElement]: isLoading,
              })}
            >
              <ArrowRightIcon />
            </div>
          )}
        </div>
      </Link>
    </button>
  );
};
