export const StoveOvenIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5 2.5C15.5 2.77614 15.7239 3 16 3C16.2761 3 16.5 2.77614 16.5 2.5V1.5C16.5 1.22386 16.2761 1 16 1C15.7239 1 15.5 1.22386 15.5 1.5V2.5Z"
      fill="black"
    />
    <path
      d="M14 2.5C14 2.77614 14.2239 3 14.5 3C14.7761 3 15 2.77614 15 2.5V2C15 1.72386 14.7761 1.5 14.5 1.5C14.2239 1.5 14 1.72386 14 2V2.5Z"
      fill="black"
    />
    <path
      d="M7.5 7.75C7.5 8.16421 7.16421 8.5 6.75 8.5C6.33579 8.5 6 8.16421 6 7.75C6 7.33579 6.33579 7 6.75 7C7.16421 7 7.5 7.33579 7.5 7.75Z"
      fill="black"
    />
    <path
      d="M16.5 7.75C16.5 8.16421 16.1642 8.5 15.75 8.5C15.3358 8.5 15 8.16421 15 7.75C15 7.33579 15.3358 7 15.75 7C16.1642 7 16.5 7.33579 16.5 7.75Z"
      fill="black"
    />
    <path
      d="M17.75 8.5C18.1642 8.5 18.5 8.16421 18.5 7.75C18.5 7.33579 18.1642 7 17.75 7C17.3358 7 17 7.33579 17 7.75C17 8.16421 17.3358 8.5 17.75 8.5Z"
      fill="black"
    />
    <path
      d="M9.5 14C9.5 13.7239 9.72386 13.5 10 13.5H14C14.2761 13.5 14.5 13.7239 14.5 14C14.5 14.2761 14.2761 14.5 14 14.5H10C9.72386 14.5 9.5 14.2761 9.5 14Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 10.75C5.58579 10.75 5.25 11.0858 5.25 11.5V19.5C5.25 19.9142 5.58579 20.25 6 20.25H18C18.4142 20.25 18.75 19.9142 18.75 19.5V11.5C18.75 11.0858 18.4142 10.75 18 10.75H6ZM6.75 18.75V12.25H17.25V18.75H6.75Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.75 5C2.75 4.58579 3.08579 4.25 3.5 4.25H5.56689C5.52435 4.17646 5.5 4.09107 5.5 4C5.5 3.72386 5.72386 3.5 6 3.5H10C10.2761 3.5 10.5 3.72386 10.5 4C10.5 4.09107 10.4757 4.17646 10.4331 4.25H13.5669C13.5243 4.17646 13.5 4.09107 13.5 4C13.5 3.72386 13.7239 3.5 14 3.5H18C18.2761 3.5 18.5 3.72386 18.5 4C18.5 4.09107 18.4757 4.17646 18.4331 4.25H20.5C20.9142 4.25 21.25 4.58579 21.25 5V22C21.25 22.4142 20.9142 22.75 20.5 22.75H3.5C3.08579 22.75 2.75 22.4142 2.75 22V5ZM4.25 5.75V21.25H19.75V5.75H4.25Z"
      fill="black"
    />
    <path
      d="M17.5 3C17.2239 3 17 2.77614 17 2.5V2C17 1.72386 17.2239 1.5 17.5 1.5C17.7761 1.5 18 1.72386 18 2V2.5C18 2.77614 17.7761 3 17.5 3Z"
      fill="black"
    />
  </svg>
);
