import React from 'react';

import cn from 'classnames';
import upperFirst from 'lodash/upperFirst';

import { Image } from '@components/Image/Image';
import { RichText } from '@components/RichText/RichText';

import styles from './HeadingGroup.module.scss';
import { LevelHeadingCondition } from '../LevelHeadingCondition/LevelHeadingCondition';
import { VideoPlayer } from '../VideoPlayer/VideoPlayer';

export const Variants = {
  default: 'default',
  heroComponent: 'heroComponent',
  myBluemovement: 'myBluemovement', // blue label
  myBluemovementBlackLabel: 'myBluemovementBlackLabel',
  takeoverHeader: 'takeoverHeader',
  checkoutActionPoint: 'checkoutActionPoint',
  confirmationHeader: 'confirmationHeader',
};

export const HeadingGroup = ({
  label,
  text,
  alignLeft,
  theme,
  backgroundImg,
  variant = Variants.default,
  image,
  video,
  contentVariables,
  isFirstLevelHeading,
}) => {
  const selectedVariant = Variants[variant] || Variants.default;

  return (
    <div
      style={{ backgroundImage: backgroundImg && backgroundImg.filename && `url(${backgroundImg.filename})` }}
      className={cn(
        !backgroundImg && styles[`contentContainerBackground${upperFirst(theme)}`],
        styles.container,
        styles[`container-${selectedVariant}`],
        {
          [styles.left]: alignLeft,
        }
      )}
    >
      {(typeof image === 'string' || image?.filename) && (
        <Image
          alt="heading group image"
          image={image}
          className={cn(styles.image, styles[`image-${selectedVariant}`])}
        />
      )}
      <LevelHeadingCondition
        isFirstLevelHeading={isFirstLevelHeading}
        className={cn(styles[`label-${selectedVariant}`])}
        label={label}
      />
      <RichText contentVariables={contentVariables} document={text} className={styles[`text-${selectedVariant}`]} />
      {video?.length > 0 && <VideoPlayer video={video[0]} className={styles.videoContainer} fullWidth />}
    </div>
  );
};
