import React from 'react';

import cn from 'classnames';

import styles from './DarkBlueButton.module.scss';

export const DarkBlueButton = ({ text, onClick, isSubmit, mobileFullWidth, disabled, children, className }) => {
  return (
    <div className={styles.container}>
      <button
        type={isSubmit ? 'submit' : 'button'}
        className={cn(styles.darkBlueButton, { [styles.fullWidth]: mobileFullWidth }, className)}
        onClick={onClick}
        disabled={disabled}
      >
        {children || <span>{text}</span>}
      </button>
    </div>
  );
};
