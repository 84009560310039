export const ShareIcon = ({ ariaHidden = true }) => (
  <svg
    aria-hidden={ariaHidden}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 7C17.5523 7 18 6.55228 18 6C18 5.44772 17.5523 5 17 5C16.4477 5 16 5.44772 16 6C16 6.55228 16.4477 7 17 7ZM17 9C18.6569 9 20 7.65685 20 6C20 4.34315 18.6569 3 17 3C15.3431 3 14 4.34315 14 6C14 7.65685 15.3431 9 17 9ZM14.0596 6.59802C14.1982 7.28311 14.5702 7.88354 15.089 8.31273L9.94038 11.4019C9.97948 11.5952 10 11.7952 10 12C10 12.2048 9.97948 12.4048 9.94038 12.598L15.0891 15.6873C15.608 15.258 16.2739 15 17 15C18.6569 15 20 16.3431 20 18C20 19.6569 18.6569 21 17 21C15.3431 21 14 19.6569 14 18C14 17.7952 14.0205 17.5952 14.0596 17.402L8.91093 14.3128C8.39197 14.742 7.72611 15 7 15C5.34315 15 4 13.6569 4 12C4 10.3431 5.34315 9 7 9C7.7261 9 8.39195 9.25796 8.91091 9.68723L14.0596 6.59802ZM18 18C18 18.5523 17.5523 19 17 19C16.4477 19 16 18.5523 16 18C16 17.4477 16.4477 17 17 17C17.5523 17 18 17.4477 18 18ZM8 12C8 12.5523 7.55228 13 7 13C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11C7.55228 11 8 11.4477 8 12Z"
      fill="#141B4A"
    />
  </svg>
);
