'use client';

import React from 'react';

import { usePathname } from 'next/navigation';

export const AnchorLink = ({ children, id, label, dataAttribute = {}, target, className }) => {
  const pathname = usePathname();

  return (
    <a href={`${pathname}#${id}`} className={className} title={label} {...dataAttribute} target={target || '_self'}>
      {children || label}
    </a>
  );
};
