import React from 'react';

import Link from 'next/link';

import { useLocale } from '@hooks/useLocale';
import { buildPath } from '@utils/paths/build-paths';

export const InternalLink = ({ children, pageId, label, className, icon, target, dataAttribute = {} }) => {
  const { locale } = useLocale();
  const href = buildPath({ locale, pageId });

  return (
    <Link target={target || '_self'} href={href} className={className} {...dataAttribute}>
      {children || (
        <>
          <span>{label}</span>
          {icon && icon}
        </>
      )}
    </Link>
  );
};
