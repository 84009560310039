export const EyeIcon = ({ ariaHidden = true }) => (
  <svg
    aria-hidden={ariaHidden}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <g id="Icons / Visibility" clipPath="url(#clip0_9183_1367)">
      <path
        id="Visibility"
        d="M12 18.1657C15.4455 18.1657 18.5182 16.3197 20.0182 13.399C18.5182 10.4784 15.4455 8.63238 12 8.63238C8.55455 8.63238 5.48182 10.4784 3.98182 13.399C5.48182 16.3197 8.55455 18.1657 12 18.1657ZM12 19.899C7.45455 19.899 3.57273 17.2037 2 13.399C3.57273 9.59438 7.45455 6.89905 12 6.89905C16.5455 6.89905 20.4273 9.59438 22 13.399C20.4273 17.2037 16.5455 19.899 12 19.899ZM12 15.5657C13.2545 15.5657 14.2727 14.595 14.2727 13.399C14.2727 12.203 13.2545 11.2324 12 11.2324C10.7455 11.2324 9.72727 12.203 9.72727 13.399C9.72727 14.595 10.7455 15.5657 12 15.5657ZM12 17.299C9.74545 17.299 7.90909 15.5484 7.90909 13.399C7.90909 11.2497 9.74545 9.49905 12 9.49905C14.2545 9.49905 16.0909 11.2497 16.0909 13.399C16.0909 15.5484 14.2545 17.299 12 17.299Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_9183_1367">
        <rect width="24" height="24" fill="white" transform="translate(0 0.899048)" />
      </clipPath>
    </defs>
  </svg>
);
